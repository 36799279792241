import _ from 'lodash'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { ENVIRONMENT } from '../environment'

BigNumber.config({ EXPONENTIAL_AT: 256 })

export const _RANDOM_BUILD_INDEX = 10
export const MAX_UINT = new BigNumber(2).exponentiatedBy(256).minus(1)

export const ETH_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
export const MATIC_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE'
export const ETH_DECIMALS = 18
export const MATIC_DECIMALS = 18

export const host = {
  teaser: {
    development: `http://localhost:${process.env.PORT || 5001}`,
    staging: 'https://teaser.dev.pods.finance',
    production: 'https://pods.finance',
    experimental: 'https://pods.finance',
    auto: () => host.teaser[ENVIRONMENT.current]
  },
  landing: {
    development: `http://localhost:${process.env.PORT || 5000}`,
    staging: 'https://landing.dev.pods.finance',
    production: 'https://pods.finance',
    experimental: 'https://pods.finance',
    auto: (slug = '') =>
      `${host.landing[ENVIRONMENT.current]}${_.toString(slug || '')}`
  },
  platform: {
    development: `http://localhost:${process.env.PORT || 5002}`,
    staging: 'https://app.dev.pods.finance',
    production: 'https://app.pods.finance',
    experimental: 'https://app.labs.pods.finance',
    auto: (slug = '') =>
      `${host.platform[ENVIRONMENT.current]}${_.toString(slug || '')}`
  }
}

export const links = {
  audit: {
    solidified: {
      link: 'https://docs.pods.finance'
    }
  },
  twitter: {
    primary: {
      link: 'https://twitter.com/podsfinance',
      username: '@PodsFinance'
    }
  },
  whitepaper: {
    link: '/pods_v1_whitepaper.pdf'
  },
  gitbook: {
    primary: {
      link: 'https://docs.pods.finance/'
    },
    faq: {
      link: 'https://docs.pods.finance/'
    },
    etherscan: {
      link: 'https://docs.pods.finance/'
    },
    AMM: {
      link: 'https://docs.pods.finance/options-amm-overview/introduction'
    },
    adminKeys: {
      link: 'https://docs.pods.finance/the-protocol/cap-and-admin-keys'
    },
    options: {
      link: 'https://docs.pods.finance/understand-options/what-are-options'
    }
  },
  discord: {
    primary: {
      link: 'https://discord.gg/Qf7utym'
    }
  },
  defipulse: {
    primary: {
      link: 'defipulse.com/'
    }
  },
  medium: {
    primary: {
      link: 'https://blog.pods.finance'
    }
  },
  github: {
    primary: {
      link: 'https://github.com/pods-finance'
    }
  },
  youtube: {
    primary: {
      link: 'https://www.youtube.com/channel/UCRbBvHcv2jVDFj7Mk5m1mSw'
    }
  },
  calendly: {
    primary: {
      link: 'https://calendly.com/podsfinance'
    }
  },
  newsletter: {
    endpoint:
      'https://sx4cu0jww2.execute-api.us-east-2.amazonaws.com/dev/subscribe'
  },
  notion: {
    hire: {
      link: 'https://angel.co/company/pods-finance'
    },
    team: {
      link: 'https://angel.co/company/pods-finance'
    }
  },
  aave: {
    about: {
      link: 'https://aave.com/'
    }
  },
  terms: {
    primary: {
      link:
        'https://www.notion.so/podsfinance/Pods-Terms-of-Use-2632469f8aae4e4e8f6aef15d4c65a29'
    },
    risks: {
      link: 'https://blog.pods.finance/risks-of-using-pods-9ea880e192e8'
    },
    analytics: {
      link:
        'https://www.notion.so/podsfinance/Pods-Analytics-cda2ecf2b6d94cc18ae2d5b584136883'
    }
  },
  faucet: {
    mycrypto: {
      link: 'https://app.mycrypto.com/faucet'
    },
    gitter: {
      link: 'https://gitter.im/kovan-testnet/faucet'
    }
  },
  ethereum: {
    primary: {
      link: 'https://ethereum.org/en/'
    }
  }
}

export const meta = {
  general: {
    title: 'Pods',
    titleFull: 'Pods Finance',
    baseUrl: 'https://www.pods.finance',
    creator: '@pods.finance',
    site: '@pods.finance',
    alt: 'Pods.Finance',
    motto: 'The future of your hedged portfolio on Ethereum.',
    description:
      "Manage your portfolio's risk by hedging your positions. Invest, get a higher APY and be exposed to assets you want. Become a liquidity provider and earn with your assets."
  }
}

export const macros = {
  ETH_ADDRESS,
  ADDRESS_ZERO: ethers.constants.AddressZero,

  ARBITRARILY_HIGH_APPROVAL_AMOUNT: 100000000,
  PREMIUM_TOKEN_SYMBOL: 'USDC',
  EXERCISE_WINDOW: 60 * 60 * 24 * 1,
  EXPIRY_NOTICE_TIME: 60 * 60 * 24 * 4,
  EXPIRY_NOTICE_TIME_LONG: 60 * 60 * 24 * 7,

  OPTION_TYPE: {
    put: 0,
    call: 1
  },

  EXERCISE_TYPE: {
    european: 0,
    american: 1
  },

  RESTRICTED_APR: '-',
  RESTRICTED_PREMIUM: '-',

  DEFAULT_SLIPPAGE: 0.05,
  DEFAULT_TIMEOUT: 60 * 20, // 20 minutes

  WEB3_MODAL_IDENTIFIER: 'WEB3_CONNECT_CACHED_PROVIDER',
  PODS_MODAL_PORTAL: 'pods-modal__container',

  COMMON_PRECISION: 6,
  MINIMUM_BALANCE_AMOUNT: new BigNumber(0.1).pow(6).toNumber(),

  ACTIVITY_PAGINATOR: 15,
  DEFAULT_NETWORK_ID: 1, // matic = 137, mainnet = 1

  SUBGRAPH_REQUIRY_DELAY: 2 * 1000 // milliseconds
}
